//import themes from "daisyui/src/theming/themes";

import BookRideForm from "./components/Form/BookRideForm";
import BookRideForm_t2b from "./components/Form/BookRideForm_t2b";
import BookRideForm_vit from "./components/Form/BookRideForm_vit";

const configs = {
  limitlesstaxi: {
    // REQUIRED
    appName: "Limitless Cabs",
    // REQUIRED: a short description of your app for SEO tags (can be overwritten)
    appDescription:
      "Taxi shuttle to or from Brussels Zaventem Airport (BRU / EBBR) or South Charleroi Airport (CRL / EBCI).",
    // REQUIRED (no https://, not trialing slash at the end, just the naked domain)
    appKeyWords:
      "taxi shuttle Brussels Zaventem Airport (BRU / EBBR) Charleroi (CRL / EBCI)",
    appTitle:
      "Taxi shuttle for Brussels Zaventem Airport and/or South Charleroi Airport",
    appFavicon: "favicon.ico",
    googleTag: false,
    googleTagId: "",

    domainName: "limitlesscabs.be",
    pricing_component: BookRideForm,
    env: {
      phone: "+32 491 25 57 63",
      email: "support@limitlesscabs.be",
      locales_dir: "locales",
      img_dir: "assets",
      primaryColor: "#F0A847",
    },
    aws: {
      // If you use AWS S3/Cloudfront, put values in here
      bucket: "bucket-name",
      bucketUrl: `https://bucket-name.s3.amazonaws.com/`,
      cdn: "https://cdn-id.cloudfront.net/",
    },
    mailgun: {
      // subdomain to use when sending emails, if you don't have a subdomain, just remove it. Highly recommended to have one (i.e. mg.yourdomain.com or mail.yourdomain.com)
      subdomain: "mg",
      // REQUIRED — Email 'From' field to be used when sending magic login links
      fromNoReply: `Taxi4All <noreply@taxi4all.com>`,
      // REQUIRED — Email 'From' field to be used when sending other emails, like abandoned carts, updates etc..
      fromAdmin: `Joris de Taxi4All <joris@taxi4all.com>`,
      // Email shown to customer if need support. Leave empty if not needed => if empty, set up Crisp above, otherwise you won't be able to offer customer support."
      supportEmail: "joris@taxi4all.com",
      // When someone replies to supportEmail sent by the app, forward it to the email below (otherwise it's lost). If you set supportEmail to empty, this will be ignored.
      forwardRepliesTo: "jorisck@gmail.com",
    },
    colors: {
      // REQUIRED — The DaisyUI theme to use (added to the main layout.js). Leave blank for default (light & dark mode). If you any other theme than light/dark, you need to add it in config.tailwind.js in daisyui.themes.
      theme: "light",
      // REQUIRED — This color will be reflected on the whole app outside of the document (loading bar, Chrome tabs, etc..). By default it takes the primary color from your DaisyUI theme (make sure to update your the theme name after "data-theme=")
      // OR you can just do this to use a custom color: main: "#f37055". HEX only.
      //main: themes["light"]["primary"],
    },
  },
  taxiToBrussels: {
    // REQUIRED
    appName: "Taxi To Brussels",
    // REQUIRED: a short description of your app for SEO tags (can be overwritten)
    appDescription:
      "Experience seamless and reliable airport shuttle services to Brussels Zaventem and Charleroi South Airports with TaxiToBrussels.",
    // REQUIRED (no https://, not trialing slash at the end, just the naked domain)
    appKeyWords:
      "taxi shuttle Brussels Zaventem Airport (BRU / EBBR) Charleroi (CRL / EBCI)",
    appTitle: "Moving you to your destination with ease",
    appFavicon: "favicon_t2b.ico",
    googleTag: true,
    googleTagId: "AW-16668709339",

    domainName: "taxitobrussels.com",
    pricing_component: BookRideForm_t2b,
    env: {
      phone: "+32 465 97 17 67",
      email: "Taxiruzzene@gmail.com",
      locales_dir: "locales_t2b",
      img_dir: "assets/assets_t2b",
      primaryColor: "#00df9a",
    },
    aws: {
      // If you use AWS S3/Cloudfront, put values in here
      bucket: "bucket-name",
      bucketUrl: `https://bucket-name.s3.amazonaws.com/`,
      cdn: "https://cdn-id.cloudfront.net/",
    },
    mailgun: {
      // subdomain to use when sending emails, if you don't have a subdomain, just remove it. Highly recommended to have one (i.e. mg.yourdomain.com or mail.yourdomain.com)
      subdomain: "mg",
      // REQUIRED — Email 'From' field to be used when sending magic login links
      fromNoReply: `Taxi4All <noreply@taxi4all.com>`,
      // REQUIRED — Email 'From' field to be used when sending other emails, like abandoned carts, updates etc..
      fromAdmin: `Joris de Taxi4All <joris@taxi4all.com>`,
      // Email shown to customer if need support. Leave empty if not needed => if empty, set up Crisp above, otherwise you won't be able to offer customer support."
      supportEmail: "joris@taxi4all.com",
      // When someone replies to supportEmail sent by the app, forward it to the email below (otherwise it's lost). If you set supportEmail to empty, this will be ignored.
      forwardRepliesTo: "jorisck@gmail.com",
    },
    colors: {
      // REQUIRED — The DaisyUI theme to use (added to the main layout.js). Leave blank for default (light & dark mode). If you any other theme than light/dark, you need to add it in config.tailwind.js in daisyui.themes.
      theme: "light",
      // REQUIRED — This color will be reflected on the whole app outside of the document (loading bar, Chrome tabs, etc..). By default it takes the primary color from your DaisyUI theme (make sure to update your the theme name after "data-theme=")
      // OR you can just do this to use a custom color: main: "#f37055". HEX only.
      //main: themes["light"]["primary"],
    },
  },
  vitrine: {
    // REQUIRED
    appName: "Vitrine Airport",
    // REQUIRED: a short description of your app for SEO tags (can be overwritten)
    appDescription:
      "Let our expert drivers get you to Brussels Zaventem and Charleroi South Airports swiftly and reliably.",
    // REQUIRED (no https://, not trialing slash at the end, just the naked domain)
    appKeyWords:
      "taxi shuttle Brussels Zaventem Airport (BRU / EBBR) Charleroi (CRL / EBCI)",
    appTitle: "Getting you where you need to go, stress-free and on time.",
    appFavicon: "favicon_vit.ico",
    googleTag: true,
    googleTagId: "AW-xxxxxx",

    domainName: "airport.vitrinesrl.com",
    pricing_component: BookRideForm_vit,
    env: {
      phone: "+32 470 41 52 06",
      email: "Resa@vitrinesrl.com",
      locales_dir: "locales_vit",
      img_dir: "assets/assets_vit",
      primaryColor: "#214A99",
    },
    aws: {
      // If you use AWS S3/Cloudfront, put values in here
      bucket: "bucket-name",
      bucketUrl: `https://bucket-name.s3.amazonaws.com/`,
      cdn: "https://cdn-id.cloudfront.net/",
    },
    mailgun: {
      // subdomain to use when sending emails, if you don't have a subdomain, just remove it. Highly recommended to have one (i.e. mg.yourdomain.com or mail.yourdomain.com)
      subdomain: "mg",
      // REQUIRED — Email 'From' field to be used when sending magic login links
      fromNoReply: `Taxi4All <noreply@taxi4all.com>`,
      // REQUIRED — Email 'From' field to be used when sending other emails, like abandoned carts, updates etc..
      fromAdmin: `Joris de Taxi4All <joris@taxi4all.com>`,
      // Email shown to customer if need support. Leave empty if not needed => if empty, set up Crisp above, otherwise you won't be able to offer customer support."
      supportEmail: "joris@taxi4all.com",
      // When someone replies to supportEmail sent by the app, forward it to the email below (otherwise it's lost). If you set supportEmail to empty, this will be ignored.
      forwardRepliesTo: "jorisck@gmail.com",
    },
    colors: {
      // REQUIRED — The DaisyUI theme to use (added to the main layout.js). Leave blank for default (light & dark mode). If you any other theme than light/dark, you need to add it in config.tailwind.js in daisyui.themes.
      theme: "light",
      // REQUIRED — This color will be reflected on the whole app outside of the document (loading bar, Chrome tabs, etc..). By default it takes the primary color from your DaisyUI theme (make sure to update your the theme name after "data-theme=")
      // OR you can just do this to use a custom color: main: "#f37055". HEX only.
      //main: themes["light"]["primary"],
    },
  },
};

const getCompanyConfig = () => {
  const company = process.env.REACT_APP_CONFIG_FILE || "limitlesstaxi";
  console.log("company", company);
  return configs[company] || configs.limitlesstaxi;
};

export default getCompanyConfig();
